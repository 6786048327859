export const correctText = [
    "Well, butter my biscuit! You've unlocked the correctness cookie jar!",
    "Did you just win the 'Guess the Answer' lottery? Because you're spot-on!",
    "You're so right, even fortune cookies consult you for accuracy!",
    "You must have a Ph.D. in 'rightness' with a minor in 'brilliance'!",
    "You're 'Right'-licious, like a correctness sundae with extra sprinkles!",
    "You're 'Right'-onimo Bay! Correctness paradise found!",
    "You're like the detective Sherlock 'Right'olmes, solving correctness mysteries!",
    "Your correctness is so bright; you should wear shades indoors!",
    "They say lightning never strikes twice, but your correctness does!",
    "Your correctness level is off the charts, and we can't even find the charts!",
    "You're so right that even the 'Eureka' moment is taking notes!",
    "You're 'Right'-eous, like the hero of correctness folklore!",
    "Did you eat a bowl of 'Right' Flakes this morning? You're positively correct!",
    "You're 'Right'-asaurus, ruling the correctness kingdom with a roar!",
    "You're 'Right'-ing the world's wrongs, one answer at a time!",
    "You're 'Right' as rainbows, and even rainbows can't argue with that!",
    "You're 'Right'-larious, making correctness funny and fabulous!",
    "You're 'Right'-upulous, like a correctness virtuoso!",
    "You're 'Right'-onificent, like a correctness masterpiece!",
    "You're 'Right'-tastic, turning correctness into a fantastic spectacle!",
    "You're so right that even the Sphinx would reveal its secrets to you!",
    "Your correctness could power a city. We should call it 'Rightropolis'!",
    "You're 'Right' on the money, and the money just got an upgrade!",
    "You're 'Right' on the mark, like an archer aiming for correctness!",
    "You're 'Right'-er than a cat trying to squeeze through a mouse hole!",
    "You're 'Right' in the bullseye, and the target is blushing!",
    "You're 'Right' as rain, and we're dancing in the correctness puddles!",
    "You're 'Right'-eous and uproarious, a true correctness comedian!",
    "You're 'Right'-ly celebrated, like a correctness holiday on the calendar!",
    "You're 'Right'-ly famous, like a star on the Hollywood Walk of Correctness!",
    "You're 'Right'-ly exceptional, like a diamond in a correctness mine!",
    "You're 'Right'-ly fantastic, like a unicorn's dream come true!",
    "You're 'Right'-ly impressive, like a magician pulling answers out of a hat!",
    "You're 'Right'-ly outstanding, like a beacon of correctness in the dark!",
    "You're 'Right'-ly remarkable, like a rare bird of correctness!",
    "You're 'Right'-ly sensational, like the front row at a correctness concert!",
    "You're 'Right'-ly spectacular, like fireworks on New Year's Eve!",
    "You're 'Right'-ly talented, like a maestro conducting a correctness symphony!",
    "You're 'Right'-ly terrific, like a superhero with a cape made of correctness!",
    "You're 'Right'-ly wonderful, like a magical correctness fairy!",
    "You're 'Right'-ly witty, like a stand-up comedian performing for correctness!",
    "You're 'Right'-ly awesome, like a superhero with a correctness shield!",
    "You're 'Right'-ly fantastic, like a wizard conjuring correctness spells!",
    "You're 'Right'-ly incredible, like a mythical creature of correctness legend!",
    "You're 'Right'-ly outstanding, like a supernova of correctness!",
    "You're 'Right'-ly remarkable, like a correctness explorer finding hidden treasures!",
    "You're 'Right'-ly sensational, like a rock star performing on the correctness stage!",
    "You're 'Right'-ly spectacular, like fireworks on New Year's Eve!",
    "You're 'Right'-ly talented, like a virtuoso who plays the correctness symphony with finesse!",
    "You're 'Right'-ly terrific, like a champion of correctness taking the gold!",
    "You're 'Right'-ly wonderful, like a treasure trove of correct answers!",
    "You're 'Right'-ly witty, like a comedy club filled with correctness jokes!",
    "You're 'Right'-ly awesome, like a superhero whose superpower is the pursuit of correctness!",
    "You're 'Right'-ly fantastic, like a magician who pulls correctness out of a hat!",
    "You're 'Right'-ly incredible, like a legend in the world of correctness!",
    "You're 'Right'-ly outstanding, like a guiding star that leads the way to correctness!",
    "You're 'Right'-ly remarkable, like a treasure trove filled with the riches of correct answers!",
    "You're 'Right'-ly sensational, like a rock star who rocks the stage of correctness!",
    "You're 'Right'-ly spectacular, like a dazzling display of fireworks celebrating correctness!",
    "You're 'Right'-ly talented, like a virtuoso who plays the correctness symphony with finesse!",
    "You're 'Right'-ly terrific, like a champion who champions the cause of correctness!",
    "You're 'Right'-ly wonderful, like a magical garden where correctness blooms in abundance!",
    "You're 'Right'-ly witty, like a comedian who finds comedy in the world of correctness!",
    "You're 'Right'-ly awesome, like a superhero who defends the world from incorrectness!",
    "You're 'Right'-ly fantastic, like a sorcerer who conjures correctness spells with flair!",
    "You're 'Right'-ly incredible, like a legend in the lore of correctness!",
    "You're 'Right'-ly outstanding, like a star that shines the brightest in the correctness sky!",
    "You're 'Right'-ly remarkable, like a hero who emerges victorious in the battle for correctness!",
    "You're 'Right'-ly sensational, like a rock star headlining the correctness tour!",
    "You're 'Right'-ly spectacular, like a dazzling display of fireworks celebrating correctness!",
    "You're 'Right'-ly talented, like a virtuoso who plays the correctness symphony with finesse!",
    "You're 'Right'-ly terrific, like a champion who conquers the world with correctness!",
    "You're 'Right'-ly wonderful, like a magical forest where the trees whisper the secrets of correct answers!",
    "You're 'Right'-ly witty, like a humorist who finds comedy in the world of correctness!",
    "You're 'Right'-ly awesome, like a superhero who defends the world from incorrectness!",
    "You're 'Right'-ly fantastic, like a sorcerer who conjures correctness spells with flair!",
    "You're 'Right'-ly incredible, like a mythical figure whose legend is built on the foundation of correctness!",
    "You're 'Right'-ly outstanding, like a guiding star",
]
