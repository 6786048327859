import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <>
            <div className='about-container'>About Us</div>
            <div className='about-body'>
                Hey there, fellow internet adventurers! Let me regale you with the epic tale of Heard, a website that's been our little baby. We built this bad boy from scratch – design, development, testing, and deployment – you name it, we did it. And boy, was it a rollercoaster ride through Soundville! <br></br> <br></br>

                So, picture this: two overly enthusiastic souls embarked on a quest to create something fun and exciting on the world wide web. What was our noble mission, you ask? To challenge you to listen to sounds and guess where they come from! Sounds fun, right? We thought so too. But here's the kicker: building this website was like trying to teach a fish to ride a bicycle. It demanded a cocktail of sleepless nights, more coffee than anyone should consume in a lifetime, and a fair share of forehead-slapping moments. Our blood, sweat, and tears might as well be in the website's code by now. And don't even get me started on the testing phase. We listened to more sounds than a DJ at a music festival – from chirping birds to roaring engines. <br></br> <br></br>
                
                Then came the day of reckoning. With trembling fingers and a prayer to the internet gods, we hit that deployment button. Our creation was out there in the wild, ready for the world to explore. It was like releasing a baby bird into the sky, hoping it would learn to fly. Now, our biggest hope is that you lovely folks out there enjoy the website and the guessing game. We want Heard to be your go-to place for fun and challenges. We want it to be as popular as that cat playing the piano video (okay, maybe not that popular, but you get the idea). In a nutshell, Heard is our digital baby, our brainchild. It's a testament to our shared love for sound, our determination, and our slightly warped sense of humor. So, join us on this awesome journey through Soundville. Let's see if your ears are up for the challenge! <br></br> <br></br>

            </div>
        </>
    )
}

export default AboutUs