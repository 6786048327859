import React from 'react';
import './Donate.css';

function Donate() {
    return (
        <>
            <div className='donate-container'>Donate</div>
            <div className='donate-body'>
                Hey there, fantastic users of our website! We've been having a blast serving up digital delights for you, but we've got a small favor to ask – think of it as our virtual tip jar. If you're enjoying using our site and want to see it get even better, consider tossing a coin into our digital hat. <br></br> <br></br>
                
                You see, building and maintaining this website is a bit like trying to feed a hungry dragon. It's an epic quest filled with coding, design, and a fair share of caffeinated nights. But dragons don't live on code alone; they need sustenance too!  <br></br> <br></br>
                
                Your generous donations won't just keep us well-fed and caffeinated; they'll also fuel our drive to make this website even more awesome. We're talking faster load times, smoother user experiences, and features so cool they'd make a polar bear in sunglasses jealous.  <br></br> <br></br>
                
                We promise to put your contributions to good use, and who knows, maybe one day we'll have a "Wall of Donors" with your name in shining pixels. Until then, keep enjoying the site, keep guessing those sounds (or whatever else we've got cooking), and know that your support means the world to us. Thanks a million, you're the real MVPs of the internet! <br></br> <br></br>
            </div>
        </>
    )
}

export default Donate