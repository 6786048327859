import moment from 'moment';

const currentDate = moment().format('L').replace(/\//g, '');
const currentDayOfYear = moment().dayOfYear();
const accessKey = "AKIASREZ544WCNKRSA2B";
const secretKey = "OamVL9W3P+e+uh2ZuGOSdoF5EFjln9Bdj/RcdMqF";
const dateISO = new Date().toISOString();
const baseUrl = {
    s3BucketUrl: "https://listenup-sounds.s3.amazonaws.com/"
}

export {
    baseUrl,
    currentDate,
    accessKey,
    secretKey,
    dateISO,
    currentDayOfYear
}