import React, { useState, useEffect } from "react";
import LevelSelection from "./LevelSelection";
import AnswerSubmission from "./AnswerSubmission";
import ErrorDisplay from "./ErrorDisplay";
// import { useNavigate } from "react-router-dom";
import { compareTwoStrings } from 'string-similarity';
import levenshtein from 'fast-levenshtein'; // Import a Levenshtein distance library
import { incorrect } from "../../utils/incorrect";
import { closeText } from "../../utils/closeText";
import LogoBg from "../../assets/svgs/logo.png";
import {
    baseUrl,
    accessKey,
    secretKey,
    currentDate,
    dateISO,
    currentDayOfYear
} from "../../utils/config";
import Papa from "papaparse";
import axios from "axios";
import "./Home.css";

const Home = () => {
    const [showLogo, setShowLogo] = useState(true);
    const [level, setLevel] = useState("");
    const [count, setCount] = useState(1);
    const [error, setError] = useState("");
    const [wrongAnswer, setWrongAnswer] = useState("");
    const [answer, setAnswer] = useState("");
    const [input, setInput] = useState("");
    const [correct, setCorrect] = useState(false)
    const [inCorrect, setInCorrect] = useState(false)
    const [hideBar, setHideBar] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // const navigate = useNavigate();

    useEffect(() => {

        // comented for now to avoid changing date in localstorage do not remove
        // if (localStorage.getItem("date") === currentDate) {
        //     if (localStorage.getItem("easyLost") && localStorage.getItem("mediumLost") && localStorage.getItem("hardLost") !== null) {
        //         navigate("/notAllowed");
        //     }
        //     if (localStorage.getItem("easyWon") && localStorage.getItem("mediumWon") && localStorage.getItem("hardWon") !== null) {
        //         navigate("/alreadyWon");
        //     }
        // }
        console.log(currentDayOfYear)
        const timer = setTimeout(() => {
            setShowLogo(false);
        }, 3000);
        if (level)
            fetchAnswer();
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [level]);

    const fetchAnswer = async () => {
        try {
            const config = {
                headers: {
                    "X-Amz-Date": dateISO,
                },
                Authorization: `AWS ${accessKey}:${secretKey}`,
            };

            const response = await axios.get(
                `${baseUrl.s3BucketUrl}keval-admin_accessKeys.csv`,
                config
            );
            console.log(`${currentDayOfYear}`, 'keval')

            Papa.parse(response.data, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    const filteredData = results.data.filter(
                        (item) => item.date === `${currentDayOfYear - 300}-${level} `
                    );
                    setAnswer(filteredData[0].value);
                    console.log(filteredData[0].value)
                },
            });
        } catch (error) {
            setError("Failed to retrieve MP3 file");
        }
    };



    const getErrorMessage = () => {
        const randomIndex = Math.floor(Math.random() * incorrect.length);
        return incorrect[randomIndex];
    }

    const getCloseMessage = () => {
        const randomIndex = Math.floor(Math.random() * closeText.length);
        return closeText[randomIndex];
    }

    const handleSubmit = () => {

        setIsButtonDisabled(true);
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 2000); // 2000 milliseconds = 2 seconds

        const levelKeys = {
            easy: {
                played: 'easyPlayed',
                streak: 'easyStreak',
                won: 'easyWon',
                lost: 'easyLost',
            },
            medium: {
                played: 'mediumPlayed',
                streak: 'mediumStreak',
                won: 'mediumWon',
                lost: 'mediumLost',
            },
            hard: {
                played: 'hardPlayed',
                streak: 'hardStreak',
                won: 'hardWon',
                lost: 'hardLost',
            },
        };

        const levelData = levelKeys[level];

        const played = localStorage.getItem(levelData.played);
        const streak = localStorage.getItem(levelData.streak);
        const won = localStorage.getItem(levelData.won);
        const lost = localStorage.getItem(levelData.lost);
        const similarity = compareTwoStrings(input.toLowerCase(), answer.toLowerCase());
        const levenshteinDistance = levenshtein.get(input.toLowerCase(), answer.toLowerCase());

        console.log(similarity, level, levenshteinDistance, 'keval sim')


        if (level === 'easy' ? similarity >= 0.5 : similarity === 1) {
            localStorage.setItem(levelData.played, played !== null ? parseInt(played) + 1 : 1);
            setWrongAnswer('');
            setCorrect(true);
            localStorage.setItem("date", currentDate);
            localStorage.setItem(levelData.streak, streak !== null ? parseInt(streak) + 1 : 1);
            localStorage.setItem(levelData.won, won !== null ? parseInt(won) + 1 : 1);
        } else {
            if (levenshteinDistance <= 2) {
                setWrongAnswer(getCloseMessage());
            }
            else {
                setWrongAnswer(getErrorMessage());

            }
            setCount((prevCount) => prevCount + 1);
            setInput("");
            if (count >= 4) {
                setWrongAnswer('Sorry you have run out of tries today. Better luck tomorrow!');
                localStorage.setItem(levelData.played, played !== null ? parseInt(played) + 1 : 1);
                localStorage.setItem(levelData.lost, lost !== null ? parseInt(lost) + 1 : 1);
                localStorage.setItem("date", currentDate);
                localStorage.setItem(levelData.streak, 0);
                setCorrect(false);
                setInCorrect(true);
            }
        }
    };


    return (
        <>
            <>
                {showLogo && (
                    <div className="logo logo-animation">
                        <img src={LogoBg} alt="logo fades in" />
                    </div>
                )}

                {!showLogo && level === "" && <LevelSelection setLevel={setLevel} />}

                {!showLogo && level !== "" && error === "" && (
                    <AnswerSubmission
                        baseUrl={baseUrl}
                        input={input}
                        setInput={setInput}
                        handleSubmit={handleSubmit}
                        wrongAnswer={wrongAnswer}
                        correct={correct}
                        inCorrect={inCorrect}
                        currentDayOfYear={currentDayOfYear}
                        level={level}
                        hideBar={hideBar}
                        setHideBar={setHideBar}
                        answer={answer}
                        count={count}
                        isButtonDisabled={isButtonDisabled}
                    />
                )}

                {!showLogo && level !== "" && error !== "" && (
                    <ErrorDisplay error={error} />
                )}
            </>
        </>
    );
};

export default Home;
