import React from 'react';
import './ContactUs.css';

function ContactUs() {
    return (
        <>
            <div className='contact-container'>Contact Us</div>
            <div className='contact-body'>
                Got something to say, ask, or share? You've come to the right place! We're thrilled that you want to reach out, and we promise not to respond in Morse code or hieroglyphics. Use the options below to connect with us:<br></br> <br></br>

                Email: Send us a digital pigeon at <font color="#C42D64">heard.webapp@gmail.com</font> <br></br> <br></br>

                Snail Mail (Just Kidding): We'd love to receive handwritten letters sealed with wax, but we live in the digital age. However, if you want to use an envelope icon in your email, we'll appreciate the effort.<br></br> <br></br>

                Telepathy (We Wish): While we're still working on implementing this feature, we'd love to hear your thoughts telepathically. Until then, email will have to do.<br></br> <br></br>

                Feedback, questions, suggestions, or just a friendly "Hello!" – we welcome it all. We promise our responses will be as witty as our website (maybe even wittier). So, fire away. Remember, we're here to make your digital experience amazing, and your input helps us do just that. Thanks for being awesome, and we can't wait to hear from you!<br></br> <br></br>
            </div>
        </>
    )
}

export default ContactUs