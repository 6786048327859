import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Play from '../../assets/svgs/play.png'
import Wrong from '../../assets/svgs/wrong.png'
import Correct from '../../assets/svgs/correct.png'
import { correctText } from '../../utils/correct';
import { tomorrowText } from '../../utils/tomorrowText';
import './AnswerSubmission.css';

const AnswerSubmission = ({ baseUrl, input, setInput, handleSubmit, wrongAnswer, correct, inCorrect, currentDayOfYear, level, hideBar, setHideBar, answer, count, isButtonDisabled }) => {
    const [playCount, setPlayCount] = useState(1);
    const audioRef = useRef(null);
    const navigate = useNavigate();

    const handleAudioEnded = () => {
        setPlayCount((prevCount) => prevCount + 1);
        document.getElementById('playCountText').setAttribute('style', 'visibility: visible')
        setHideBar(true)
    };

    const hideElements = () => {
        ['hheader', 'hplayer', 'playCountText', 'hcorrect', 'hwrong'].forEach((id) => {
            document.getElementById(id).style.visibility = 'hidden';
        });
    };

    const showMessage = (id, textArray) => {
        hideElements();
        document.getElementById(id).setAttribute('style', 'visibility: visible');
        const randomIndex = Math.floor(Math.random() * textArray.length);
        return textArray[randomIndex];
    };

    const getSuccessMessage = () => showMessage('hcorrect', correctText);
    const getWrongMessage = () => showMessage('hwrong', tomorrowText);

    const handlePlayer = () => {

        if (audioRef.current && playCount <= 4) {
            document.getElementById('playCountText').setAttribute('style', 'visibility: hidden')
            audioRef.current.play();
            setHideBar(false)
        }
    }
    return (
        <>
            <div id='hheader' className='header-text'>
                Turn up the volume.<br></br>
                Guess that sound!
            </div>
            <div id='hplayer' className="player">
                {hideBar ? (
                    <img src={Play} alt="play button" onClick={handlePlayer} className='heardbars-action' />
                ) : (
                    <>
                        <div className="cssload-container">
                            <ul className="cssload-loader">
                                {[...Array(6)].map((_, i) => (
                                    <li key={i}></li>
                                ))}</ul>
                        </div>
                    </>
                )
                }
                <audio ref={audioRef} src={`${baseUrl.s3BucketUrl}${currentDayOfYear - 300}-${level}.wav`} onEnded={handleAudioEnded} />
                <div id='playCountText' className='playcount-text'>
                    {`${5 - playCount} sound replays left`}
                </div>
            </div>
            <div className='end-game'>
                <img id='hcorrect' src={Correct} alt="correct" className='correct-img'></img>
                <img id='hwrong' src={Wrong} alt="wrong" className='wrong-img'></img>
            </div>
            <div className='player-main'>
                {correct && <div className='correct-answer-text'> {getSuccessMessage()} </div>}
                {wrongAnswer !== '' ?
                    <>
                        <div className='wrong-answer-text'>
                            {wrongAnswer} {`(You have ${5 - (count)} tries remaining)`}
                        </div>
                        {count > 4 &&
                            <div className='correct-answer-value'>
                                The answer is: {answer} <br></br>
                                {getWrongMessage()}
                            </div>
                        }
                    </>
                    : (null)}
                {!correct && !inCorrect && (
                    <input
                        type="text"
                        className="player-input"
                        name="userInput"
                        placeholder="Type your guess here..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                    />
                )}
                {(correct || inCorrect) ? (
                    <button className="player-button" onClick={() => navigate("/stats")}>
                        View Stats
                    </button>
                ) : (
                    <button className="player-button" disabled={isButtonDisabled} onClick={handleSubmit}>
                        {isButtonDisabled ? 'Please wait...' : 'Submit'}
                    </button>
                )}

            </div >

        </>
    );
};

export default AnswerSubmission;
