export const incorrect = [
    "Oops, not quite",
    "Not quite right, but don't give up",
    "Close, but not quite there. Keep going",
    "Not the answer we were looking for, but keep trying",
    "A valiant effort, but let's give it another shot",
    "You're on the right track! Try one more time",
    "Almost there! Keep those guesses coming",
    "Not this time, but your perseverance is admirable",
    "You're so close! Keep going, champ",
    "Oh, a near miss! Keep the momentum going",
    "Not quite, but your determination is inspiring",
    "Almost had it! Keep the answers coming",
    "Good effort, but we're not there yet. Keep trying",
    "Nice try! We're cheering you on",
    "Close but not quite! Keep pushing forward,",
    "You're getting warmer! Try again",
    "Not this time, but don't lose heart",
    "A solid attempt, but let's try again",
    "You're so close! Try once more",
    "You're on the brink of success! Keep going",
    "Almost perfect! Try another shot",
    "Close, but no cigar. Try again",
    "Not quite, but you're doing great",
    "Oh, a near miss! Keep up the good work",
    "Oops, not quite there. Another attempt, perhaps?",
    "Close, but not the bullseye. Give it another shot!",
    "Almost, but not quite the right answer. Try again!",
    "Not quite on the money. How about another go?",
    "You're on the right track, but it's not the winning ticket. Try one more time!",
    "Almost a touchdown, but not quite. Give it another play!",
    "Nice try, but the puzzle pieces don't fit yet. Another attempt, please!",
    "You're in the ballpark, but the home run is elusive. Try again!",
    "Close call, but not the right answer. Another attempt, please!",
    "You're warming up, but it's not the final round. Try once more!",
    "Almost a match, but not the right answer. Try another attempt!",
    "Not quite the jackpot. Another spin of the wheel, perhaps?",
    "You're getting warmer, but it's not the correct answer. Try again!",
    "Almost there, but it's not the finish line. One more attempt, please!",
    "You're on the right road, but not at the destination yet. Try one more time!",
    "Close, but not the winner's circle. Give it another shot!",
]