export const closeText = [
    "Well, you're dancing the cha-cha, but we need a bit more salsa!",
    "Close, but it's like ordering fries and getting zucchini. Keep frying!",
    "Almost there! It's like trying to plug in a USB cable on the first try.",
    "Nice try, but you're in the bicycle lane, and we need you on the highway!",
    "You're in the right ballpark, but we're aiming for the home run!",
    "You're in the right solar system; we just need to shoot for the stars!",
    "Almost perfect, but it's like being one puzzle piece away from completing the Mona Lisa.",
    "Not quite, but it's like catching a fish and realizing you need a bigger boat!",
    "You're so close you can smell success, but don't stop now; we're going for the taste!",
    "Close, but it's like trying to find Waldo in a crowd of Wallys.",
    "Almost got it! It's like trying to break the sound barrier with a kazoo.",
    "You're warmer than a toaster, but let's crank up the heat!",
    "Not quite, but you're closer than a penguin at the equator!",
    "You're on the right path, but it's more of a 'Yellow Brick Road' kind of journey!",
    "Almost perfect! It's like trying to bake a cake with one ingredient missing.",
    "You're in the right neighborhood, but we're house hunting on the moon!",
    "Almost there! It's like trying to win a hot dog eating contest with one bite to go.",
    "You're in the ballpark, but we're hunting for Bigfoot in the forest of correctness!",
    "You're hotter than a jalapeño, but let's aim for habanero-level spiciness!",
    "Almost got it! It's like trying to win a watermelon-eating contest with one slice to go.",
    "You're on the right track, but we're building a rocket ship to the moon!",
    "You're in the right hemisphere; we just need to shoot for the home run in the other one!",
    "Almost perfect! It's like trying to bake a cake without flour – we need that ingredient!",
    "Close, but it's like trying to knit a sweater for a giraffe. We need a bigger plan!",
    "You're so close you could touch success with a selfie stick, but keep reaching!",
    "Not quite, but you're closer than a kangaroo at a hopscotch tournament!",
    "You're in the right wavelength, but we're dialing in to the cosmic correctness channel!",
    "Almost there! It's like trying to set a world record for high-fiving with one high-five to go.",
    "Close, but it's like trying to knit a scarf for a giraffe using dental floss. We need more thread!",
    "You're hotter than the sun, but let's turn up the heat to 'stellar core' level!",
    "You're on the right wave, but we're surfing the cosmic correctness tsunami!",
    "Almost got it! It's like trying to break the world record for bubblegum blowing with one bubble to go.",
    "Close, but it's like trying to find a snowflake in a blizzard. Keep searching!",
    "You're so close, you could high-five success, but it's time for a victory buffet!",
    "Not quite, but you're closer than a penguin on a tropical beach!",
    "You're on the right planet, but we're launching a spaceship to the galaxy of correctness!",
    "Almost perfect! It's like trying to bake a cake without butter – we need that smoothness!",
    "Close, but it's like trying to knit a sweater for a giraffe using dental floss. We need more thread!",
    "You're hotter than the sun, but let's turn up the heat to 'stellar core' level!",
    "You're on the right cloud, but we're skydiving into the stratosphere of correctness!",
    "Almost there! It's like trying to set a world record for high-fiving with one high-five to go.",
    "Close, but it's like trying to find a grain of sand in a desert. Keep sifting!",
    "You're so close, you could smell success, but it's time to take a deep breath!",
    "Not quite, but you're closer than a snowman at the equator!",
    "You're on the right planet, but we're launching a spaceship to the galaxy of correctness!",
    "Almost perfect! It's like trying to bake a cake without vanilla extract – we need that flavor!",
    "Close, but it's like trying to knit a scarf for an octopus. We need more arms!",
    "You're hotter than a supernova, but let's turn up the heat to 'black hole' level!",
    "You're on the right wave, but we're surfing the cosmic correctness tsunami!",
    "Almost got it! It's like trying to break the world record for bubblegum blowing with one bubble to go.",
    "Close, but it's like trying to find a snowflake in a blizzard. Keep searching!",
    "You're so close, you could high-five success, but it's time for a victory parade!",
    "Not quite, but you're closer than a snowman in a sauna!",
    "You're on the right wavelength, but we're dialing in to the universal radio station of correctness!",
    "Almost perfect! It's like trying to bake a cake without vanilla extract – we need that flavor!",
    "Close, but it's like trying to knit a scarf for an octopus. We need more arms!",
]

