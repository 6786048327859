import React from 'react';
import './LevelSelection.css'
import Play from '../../assets/svgs/infoIcon.png'
import Close from '../../assets/svgs/remove-icon.png'

const LevelSelection = ({ setLevel }) => {
    const handleLevelSelection = (level) => {
        setLevel(level);
    };

    // Inline popup
    function showPopup() {
        document.getElementById("howto-popup").setAttribute('style', 'visibility: visible')

        // var fade = document.getElementById("howto-popup");
        // var opacity = 0;
        // var intervalID = setInterval(function() {
      
        //     if (opacity < 1) {
        //         opacity = opacity + 0.1
        //         fade.style.opacity = opacity;
        //     } else {
        //         clearInterval(intervalID);
        //     }
        // }, 50);
    }

    function closePopup() {
        document.getElementById("howto-popup").setAttribute('style', 'visibility: hidden')
    }

    return (
        <>
            <div className='container'>
                <div id="howto" className='how-to-container'>
                    <span className="select-text" onClick={() => showPopup()}>Choose your path!  
                    <span onClick={() => showPopup()}> 
                    <img className="how-to-icon" data-effect="mfp-zoom-in" src={Play} alt=''></img></span>
                    </span>
                </div>

                <div className="level-text" onClick={() => handleLevelSelection('easy')}>
                    Easy peazy...   (Easy)
                </div>
                {/* 
                <div className="level-text" onClick={() => handleLevelSelection('medium')}>
                    Not so breezy... (Medium)
                </div> */}

                <div className="level-text" onClick={() => handleLevelSelection('hard')}>
                    Got me dizzy...   (Hard)
                </div>

                <div id="howto-popup" className="white-popup">
                    <div className='howtoplay-container'>How To Play</div>
                    <img className="close-how-to-icon" src={Close} onClick={() => closePopup()} alt=''></img>
                        <div className='howtoplay-body'>
                            1. Choose your adventure difficulty (remember, hard mode only nibbles a little). <br></br> <br></br>

                            2. Turn up the volume and hit that Play button! You've only got 4 sound plays, so listen like it's your last disco party.<br></br> <br></br>

                            3. Take wild guesses at what that sound could be – you've got 5 chances to hit the bullseye!<br></br> <br></br>

                            4. Drop by each day to guess the latest sound. Who knows, maybe one day you'll become the ultimate sound wizard. LET THE GOOD TIMES ROLL!<br></br> <br></br>
                    </div>              
                </div>
            </div>
        </>
    );
};

export default LevelSelection;
