import React from 'react';
import './HowToPlay.css';

function HowToPlay() {
    return (
        <>
            <div className='howtoplay-container'>How To Play</div>
            <div className='howtoplay-body'>
                1. Choose your adventure difficulty (remember, hard mode only nibbles a little). <br></br> <br></br>

                2. Hit that Play button and prepare to soak up the sweet sound vibes! But beware, you've only got 4 sound plays, so listen like it's your last disco party.<br></br> <br></br>

                3. Time to become the Sherlock of sounds! Take wild stabs at what that noise could be – you've got 5 chances to hit the bullseye. Aim, fire, and pray for a bingo!<br></br> <br></br>

                4. LET THE GOOD TIMES ROLL! Keep swinging by each day to throw your guesses into the ring. Who knows, maybe one day you'll become the ultimate sound wizard!<br></br> <br></br>
            </div>
        </>
    )
}

export default HowToPlay